@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto:wght@400;500&display=swap');

::-webkit-scrollbar {
 height: 2px;
  width: 2px;
  background: #f3eded;
}

::-webkit-scrollbar-thumb {
  background: #103667;
  -webkit-border-radius: 1ex;
  /* -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75); */
}

::-webkit-scrollbar-corner {
  background: white;
}

/* ::-webkit-scrollbar {
  display: none;
} */


.listItemtext {
  text-decoration: none;
  color: #333;
}

/* .listItemtext :hover{
    text-decoration: underline;
} */

.textFile {
  text-decoration: none;
  color: #000;
}

.textFile:hover {
  text-decoration: underline;
}

#file-upload-button {
  background-color: #fff !important;
}

.ag-theme-alpine .ag-header-cell {
  font-family: "roboto", sans-serif !important;
  font-weight: bold;
}

.makeStyles-root-1 {
  /* margin-top: 5px !important; */
  font-weight: 400 !important;
  font-family: "roboto" , sans-serif !important;
}

.ag-header-cell-text {
  font-weight: bold !important;
}

.list {
  padding: 0 !important;
}

.list:hover {
  text-decoration: underline;
}

/* .ag-right-aligned-cell.ag-cell-value {
    justify-content: flex-start;
 } */
.submitbtn:hover {
  background-color: #555a64 !important;
  color: '#fff',
}

.upload-error {
  color: darkred;
}


.dialogTitle {
  background-color: #00ae39 !important;
  padding: 2px 10px;
  text-align: "center";
  border-radius: 5px;
  display: inline-block;
  color: #fff
}

.blueColor {
  background-color: #145291 !important;
  color: '#fff'
}

.greyColor {
  background-color: #555 !important;
  color: #fff;
}

.btn:hover {
  cursor: pointer;
}

.lightGreenColor {
  background-color: #638d09 !important;
  color: #fff;
}

.pointer {

  /* margin-right: 10px; */
  list-style: none;
  text-align: center;
  width: 100px;
}

.pointerIcon {
  position: relative;
  width: 100px;
}

.countryText {
  /* position: absolute;
    font-size: 14px; */
  font-size: 16px;
  font-weight: bold;

}

.countryType {
  font-size: 12px;
}

.destinationText {
  /* left: 10px; */
}

.widthNone {
  width: auto !important;
}




.card {
  margin-bottom: 16px;
  background-color: #fff;
  border: 1px solid #eceff5;
  box-shadow: none;
}

.card-body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.bg-light-alt {
  /* background-color: #fbfbfb!important; */
}

.thumb-md {
  height: 48px;
  width: 48px;
  font-size: 18px;
  font-weight: 700;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.ti {
  font-family: 'tabler-icons', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #7081b9 !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.iconContent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.mainGrid {
  padding-left: calc(1rem * .2);
  padding-right: calc(1rem * .2);
  position: relative;
}

.card-title {
  text-transform: capitalize;
  letter-spacing: .02em;
  font-size: 14px !important;
  font-weight: 500;
  margin: 0;
  color: #303e67;
}

.card-header {
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #eceff5;
  border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0;
  letter-spacing: 0.2em;
  text-transform: capitalize;
}

.etaDeviations {
  font-size: 12px;
  border-collapse: separate;
  border-spacing: 0px 16px;
}

.etaDeviations tr {
  border-bottom: 1px solid #ccc;
  padding: 15px 0px;
  /* display: inline-block; */
}

.etaDeviations td:first-child {
  width: 150px;
}

.eta-text-bg {
  padding: 2px 5px;
  color: #fff;
  border-radius: 10px;
  background-color: #333;


}

.ti-users {
  width: 40px;
}

.footer {
  bottom: 0;
  padding: 16px;
  position: absolute;
  /* right: 0; */
  justify-content: space-between;
  width: calc(100% - 80px);
  left: 80px;
  color: #333;
  box-shadow: 0px 0px 5px #f1f1f1;
  font-size: 16px;
  border-top: 1px solid #eceff5;
  display: flex;
}

/* .dashboard_info:hover{
  text-decoration: underline;
  cursor: pointer;
} */


#roll_back {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  border-radius: 5px;
  padding: 5px 10px;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  box-shadow: 0px 6px 16px -6px rgba(1, 1, 1, 0.5);
  font-weight: 900;
}

.wrapper {
  /* height: 100vh; */
  display: flex;

  /* Direction of the items, can be row or column */
  /* flex-direction: column; */
}


/* footer {
    height: 30px;
} */

main {
  flex: 1;
}

.footer_img {
  width: 130px;
}

.changePassword_footer_title {
  justify-content: center;
  color: #fff;
  width: 100%;
  display: flex;
  text-decoration: none;
}

.footer_title {

  color: #333;
  text-decoration: none;
  display: flex;
}

.footer_img :hover {
  text-decoration: underline;
  cursor: pointer;
}

.common_table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.common-subRows th {
  color: #333;
  border: 1px solid #ccc;
  border-collapse: collapse;
  font-size: 0.85rem;

}

.title-info {
  width: 100%;
  background-color: #7081b9;
  color: #fff;
  padding: 10px;
}

.common-subData td {
  text-align: center;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.tableCellData {
  color: blue;

}

.tableCellData:hover {
  text-decoration: underline;
  cursor: pointer;
}

.multi {
  position: relative;
  z-index: 1000;
}

.dashboard-title {
  font-weight: bold !important;
  font-size: 0.85rem !important;
}

.steps-title {
  font-size: 14px !important;
}

.steps-time {
  position: relative;
  top: 20px;
  background: white;
  border: 1px solid #BEBEC1;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.main-stepper {
  padding: 10px 0 7px 0px;
  border-bottom: 1px solid #ccc;
}

.seaRates_main_header {
  /* margin: 0 auto; */
  width: 100%;
  /* max-width: 1180px; */
  border-radius: 10px;
  background-color: #e9f0f7;
  padding: 10px 10px 10px 10px;
  animation: _37voVng3nKHA4uc-Tzq1vG .2s ease;
}

.seaRates_header {
  display: flex;
  align-items: flex-end;

}

.seaRates_header1 {
  width: 24%;
  max-width: 192px;
}

.seaRates_text {
  font-size: 12px !important;
  line-height: 14px;
  color: #9fb1c1;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0 0 6px 0;
}

.seaRates_body {
  display: flex;
  position: relative;
  background-color: #fff;
  border-radius: 5px 0 0 5px;
}

.seaRates_body1 {
  position: relative;
  display: flex;
  height: 60px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  color: #9fb1c1;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1;
}

.seaRates_content {
  background-color: rgb(1, 57, 255);
  color: #fff;
  font-size: 12px;
  padding: 14px 10px;
  height: 50px;
  border-radius: 5px;
  width: 50px;
  margin: 5px 2px;
}

.seaRates_content1 {
  position: absolute;
  top: 50%;
  left: 8%;
  width: 84.4%;
  height: 84%;
  border-radius: 5px;
  z-index: -1;
  transform: translate3d(0, -50%, 0);
  transition: all .18s ease;

  background-color: rgb(1, 57, 255);
  box-shadow: rgba(4, 114, 235, 0.5) 0px 0px 3px;
  /* transform: translateY(-50%) translateX(0px); */
}

.seaRates1_header {
  flex-grow: 1;
  width: calc(50% - 10px);
}

.seaRates1_header1 {
  display: flex;
  flex-direction: row;
}

.seaRates1_body {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: row;
}

.swap {
  top: 10px;
  width: 27px;
  height: 27px;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #0139ff !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #fff !important;
  border-radius: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
  z-index: 2;
  /* transform: translate3d(-50%, -50%, 0) scale(1); */
  transition: all .2s ease;
}

.rate {
  width: 100%;
  background-color: #eaf5ff !important;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  max-width: 50px;
  transition: opacity .2s;
  border: 0;
  margin-right: 15px !important;
  height: auto;
}

.bookNow {
  width: 100%;
  background-color: #0139ff;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  max-width: 250px;
  transition: opacity .2s;
  border: 0;
  height: auto;
}

.finalBooking {
  font-weight: 600;
  font-size: 14px;
  color: #15354e !important;
  padding: 1px 6px !important;
  background: rgba(0, 0, 0, 0);
  border: 0;
  height: auto;
  margin-top: 10px !important;
  margin-left: 40px !important;
}

.tabFilter {
  /* padding: 0px 10px;
  margin-bottom: 10px; */
  font-size: 16px !important;
}

.grid-header {
  background-color: #e9f0f7 !important;
  border-radius: 5px;
  padding: 10px;

}

.activeChips {
  width: 100%;
  padding: 20px !important;
  /* border: none !important; */
  background-color: #fff !important;
  border: 1px solid #f1f1f1 !important;
  border-radius: 30px !important;
  color: #0139ff !important;
  box-shadow: 0 0 20px rgba(105, 126, 157, .1);
}

.inActiveChip {
  width: 100%;
  padding: 20px !important;
  border: none !important;
  background: none !important;
}

.Tariff_info {
  width: 100%;

  display: flex;
}

.info_1 {
  width: 300px;
  float: left !important;
}

.custome_Input_dropdown {
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  margin-top: '10px';
}

.shipments_img {
  width: 50px !important;
  height: 100% !important;
  border-radius: 50% !important;
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.hscodes_header {
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;

}

.hscodes_content {
  height: 250px;
  overflow-y: auto;
  margin: 10px 0px;
  cursor: pointer;
}

.breadCrumbs {
  background-color: #f0f9e5;
  color: #7da94a !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;

}

.hscodes_info {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

.hscodes_info:hover {
  background-color: #f8f9fd;
  cursor: pointer;
}

.hsCodes {
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 10px;
}

.hsCodes:hover {
  cursor: pointer;
  text-decoration: underline;
}

.seaRates_Filter_Headers {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.seaRates_Filter_Content {
  font-size: 14px !important;
}

.rates_info {
  text-transform: uppercase;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.stepper {
  width: 100%;
  padding: 15px 25px 35px;
}

.stepper_content_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #15354e;
  font-weight: 500;
  font-size: 14px;
}

.step_title {
  text-overflow: ellipsis;
  overflow: hidden;
}

.steps {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.steps_main {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  min-height: 14px;
}

.steps_circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #ccc;
  cursor: pointer;
}

.steps_circle:after {
  background-color: #02bcb1;
}

/* .steps_main::after {
  background: #02bcb1;
} */
.steps_main.steps_blue .steps_circle {
  border-color: #0139ff
}

/* .steps_main.steps_cyan .steps_circle{
  border-color: #02bcb1
} */
.steps_main.steps_cyan .steps_circle.steps_circle_active {
  border-color: #02bcb1
}

.steps_main.steps_cyan .steps_circle.steps_circle_inactive {
  border-color: #ccc;
}

.steps_main.steps_blue .steps_circle.steps_circle_active {
  border-color: #0139ff;
  cursor: not-allowed;
}

.steps_main.steps_blue .steps_circle.steps_circle_inactive {
  border-color: #ccc;
}

.steps_main.steps_cyan .steps_circle.steps_circle_active:after {
  background: #02bcb1 !important;
}

.steps_main.steps_cyan .steps_circle.steps_circle_inactive:after {
  background: #ccc !important;
}

.steps_main.steps_cyan_active:after {
  background-color: #02bcb1;
}

.steps_main.steps_cyan_inactive:after {
  background-color: #ccc;
}

.steps_icon {
  width: 15px !important;
  height: 15px !important;
  fill: rgb(2, 188, 177) !important;
  position: absolute;
  top: -12px;
  left: 49%;
  font-size: 20px;
}

.steps_icon_inactive {
  display: none;
}

.steps_main.steps_blue::after {
  background-color: #0139ff;
}

.steps_main.steps_blue .steps_icon {
  fill: #0139ff !important;
}

.steps_main.steps_blue.steps-blue-cyan_active .steps_icon {
  fill: #02bcb1 !important;
}

.steps_main.steps_blue.steps-blue-cyan_active:after {
  background-color: #02bcb1
}

.steps_main.steps_blue.steps-blue-cyan_inactive:after {
  background-color: #ccc
}

.steps_main:after {
  content: "";
  position: absolute;
  left: 19px;
  width: calc(100% - 24px);
  height: 1px;
  background: #ccc;
}

.steps_tooltip {
  display: none;
  position: absolute;
  top: -90px;
  z-index: 99;
  cursor: pointer;
}

.steps_tooltip_header {
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: rgba(10, 20, 58, .9);
  color: #fff;
  border-radius: 5px;
  min-width: 110px;
  width: 220px;
  overflow: hidden;
}

.steps_tooltip_content {
  width: 100%;
  position: relative;
  padding-bottom: 4px;
}

.steps_circle:hover .steps_tooltip {
  display: block;
}

.steps_main:last-child {
  flex: 0;
}

.steps_main:last-child::after {
  display: none;
}

.steps_delay_days {
  position: absolute;
  top: -36px;
  left: calc(50% - 15px);
  text-align: center;
  color: #15354e !important;
  font-size: 12px !important;
  font-weight: 600;
  width: 100%;
  max-width: 100px;
  margin-left: auto;
  display: flex;
}

.stepper_content_bottom {
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -20px;
  left: -45%;
}

.step_bottom-title {
  position: relative;
  color: #15354e !important;
  font-size: 12px !important;
}

.siteDetails {
  background-color: #ddd;
  color: #000;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;

}

.shipment_header {
  background-color: #ddd;
  color: #000;
  font-size: 14px !important;
  font-weight: bold;
  padding: 5px 20px;
  border-radius: 10px 10px 0px 0px;
}

.additional_headers {
  padding: 10px;
  background-color: #ddd;
  color: #000;
  border-radius: 10px 10px 0px 0px;
}

.changePassword {
  background-color: #5046e5;
  height: 100vh;
}

.header_logo {
  width: 20%;
  /* float: right; */
}

.changePassword_logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 10px;
  max-width: 500px;
}

.custom_select {
  padding: 6.5px 14px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  color: #b3b3b3 !important;
  font-size: 1rem;
}

.custom_select:focus-visible {
  border: 2px solid #3498db !important;
}

.otpInput {
  padding: 10px;
  width: 50px !important;
  height: 50px;
  border: 1px solid #3498db !important;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
}

.otpInput-header {
  margin: 10px 30%
}

.dummyPage {
  color: #fff;
  padding: 10px;
}

.submit {
  background-color: #1976d2 !important;
  padding: 6px 16px !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border-radius: 4px !important;
  min-width: 64px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

}

.reset {
  background-color: #757575 !important;
  color: #fff !important;
  padding: 6px 16px !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border-radius: 4px !important;
  min-width: 64px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

}

.shipperView_header {
  background-color: #ddd;
  color: #1976d2;
  padding: 10px;
  font-weight: bold;
  font-size: 14px !important;
}

.shipperView_list {
  background-color: #ecf0f147;
}

.custome_text {
  font-size: 14px !important;
  font-weight: bold;
}

.shipper_subHeader {
  background-color: ghostwhite;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
}

.shipper_subHeader_List {
  display: flex;
  justify-content: space-between;
}


.digital_steps {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  min-height: 14px;
}

.custom_steps {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #676767;
  cursor: pointer;
}

.custome_step_circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #676767;
  background: #676767;
  cursor: pointer;
}

.steps_border {
  color: #15354e;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -20px;
  /* left: -45%; */
}

.digital_steps:after {
  content: "";
  position: absolute;
  left: 20px;
  width: calc(100% - 20px);
  height: 1px;
  background: #ccc;
}

.digital_steps:last-child {
  flex: 0;
}

.steps_vertical_border {
  border-left: 2px solid #ccc;
  top: 35px;
  height: 50px;
  right: 11px;
  position: relative;
}

.triangle_down {
  width: 0;
  height: 0px;
  border-top: 10px solid #676767;
  font-size: 12px;
  top: 40px;
  right: 28px;
  position: relative;
}

.steps_vertical_border:last-child {
  flex: 0;
  border-left: none;
}

.digital_steps::after:last-child {
  background: none;
}

.popuptext {
  width: 100%;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  cursor: pointer;
  border: 1px solid #ddd;
}

.popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.popuptext .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.basic-single {
  font-size: 14px;
  width: 20%;
}

.toolTip:hover {
  text-decoration: underline;
}

.toolTip {
  color: #1976d2 !important;
}

.reactSelect {
  font-size: 1rem !important;
}

.listItemtext .disable {
  user-select: none;
  color: '#ddd';
  cursor: not-allowed;
}

.shipmentInfoTitle {
  font-weight: bold !important;
  margin-bottom: 10px;
}

.shipmentPortInfo {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.scrollbar-container {
  overflow-y: scroll;
  height: 100vh;
}

.origin {
  color: #007ba8 !important;
}

.duration {
  top: 20px;
  background: white;
  border: 1px solid #bebec1;
  padding: 5px;
  font-size: 12px;
  justify-content: center;
  align-items: center;

}

.searchBar {
  height: 35px !important;
  margin-top: 10px;
  font-size: 12px;
}

.disabled {
  color: #333;
  user-select: none;
  cursor: not-allowed;
  text-decoration: none;
}

.custom-search {
  border: 1px solid #ced4da !important;
  color: #495057 !important;
  border-radius: 0.25rem !important;
  height: 40px !important;
}

/* .lnndaO {
  white-space: pre-line !important;
} */

/* .rdt_TableCol div:first-child {
  /* white-space: normal !important;
  overflow: visible !important; */
/* width:100px */
/*  */

.QHBTy {
  color: 'black' !important;
  font-family: 'Roboto' ,sans-serif !important;
  font-weight: 'bold' !important;
}

.custom-autocomplete .MuiAutocomplete-inputRoot {
  position: relative;
}

.custom-autocomplete .MuiAutocomplete-inputRoot:before {
  content: none;
}

.custom-autocomplete .MuiAutocomplete-inputRoot:after {
  content: none;
}

.custom-autocomplete .MuiAutocomplete-popupIndicator {
  display: none !important;
}

.custom-file-upload {
  transform: rotate(20deg);
}

.custom-active-event {}

.custom-chip {
  font-size: 10px !important;
  /* background-color: #95b027 !important; */
  background-color: #06BF06 !important;
  /* background-color: tomato !important; */
  border-radius: 5px !important;
  height: 16px !important;
  /* margin-left: 20px; */
  /* margin-top: 10px; */

}

.custom-icon-colors {
  filter: invert(1);
}

/* .hoverUnderscore:hover {
  text-decoration: 'underline';
  cursor:'pointer';
} */

.multiSelect {
  width: 315px !important;
}

.shipmentInfoHide {
  display: none;
}

.swal-icon--info {
  border-color: #facea8;
  color: #f8bb86;
}

.swal-icon--info:before {
  border-color: #facea8;
  color: #f8bb86;
  background-color: #f8bb86;
}

.swal-icon--info:after {
  border-color: #facea8;
  color: #f8bb86;
  background-color: #f8bb86;
}

.swal-title {
  font-size: 16px;
}

.swal-footer {
  text-align: center;
}

.forgotPassword:hover {
  color: blue
}

.co2Emissions {
  /* background-color: #04852f;
  color: #fff; */
  font-weight: bold !important;
  /* padding: 5px 10px;
  border-radius: 10px; */
}

.kQhARV {
  font-size: 14px;
}

.ocean-image {
  background-image: url('./asserts/Ocean-Image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* height: 100%; */
}

.air-image {
  background-image: url('./asserts/Air-Image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.custom-inputs {
  background-color: #fff;
  border-radius: 4px;
}

.custom-intransit-info {
  /* position:absolute;
  right:0;
  color: #fff;
  padding: 2px;
  border-radius: 5px;
  font-size: 12px; */
  position: absolute;
    top: 0;
    right: 0;
    /* padding-right: 2px; */
    margin-right: 3px;
    padding: 2px;
    color:#fff;
    border-radius: 0px 10px;
    font-size: 12px;

}
.custom-label{
  color: #fff !important;
  font-size: 14px !important;
}
.ShipmentTitle{
  background-color: #ddd;
  padding:10px;
}
.ShipmentInfo{
  padding: 15px;
  max-height: 350px;
  min-height: 350px;
  
}
